import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { INQUIRY_PROCESS_NAME, resolveLatestProcessName } from '../../transactions/transaction';

import { Heading, Modal, NamedLink } from '../../components';
import UserCard from './UserCard/UserCard';
import InquiryForm from './InquiryForm/InquiryForm';

import css from './ListingPage.module.css';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    isIframe = false,
    creator = null,
  } = props;

  if (!listing.author) {
    return null;
  }

  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isInquiryProcess = processName === INQUIRY_PROCESS_NAME;

  const inquiryFormComponent = <InquiryForm
    className={css.inquiryForm}
    submitButtonWrapperClassName={isIframe ? css.inquirySubmitButtonWrapperIframe : css.inquirySubmitButtonWrapper}
    listingTitle={title}
    authorDisplayName={authorDisplayName}
    sendInquiryError={sendInquiryError}
    onSubmit={onSubmitInquiry}
    isIframe={isIframe}
    inProgress={sendInquiryInProgress}
  />;

  return (
    <div id="author" className={css.sectionAuthor}>

      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
          <FormattedMessage id="ListingPage.aboutProviderTitle" />
        </Heading>
      <div className={css.authorWrapper}>
      <div className={css.sectionAuthorPanel}>
        <UserCard
          isIframe={isIframe}
          user={listing.author}
          currentUser={currentUser}
          onContactUser={onContactUser}
          hasCreator={!!creator}
          // showContact={!isInquiryProcess}
          showContact={false}
        />

        {creator ? <UserCard
          isIframe={isIframe}
          user={creator}
          hasCreator={true}
          isCreator={true}
          showContact={false}
          listing={listing}
        /> : null}
      </div>

      {!isIframe && !isAuthenticated ? <div className={css.sellYoursPanel}>
        <p className={css.sellYoursText}>
          <FormattedMessage id="ListingPage.sellYoursTitle" />
        </p>
        <p className={css.sellYoursSubText}>
          <FormattedMessage id="ListingPage.sellYoursSubTitle" />
        </p>
        <NamedLink className={css.sellYoursLink} name="SignupForUserTypePage" params={{ userType: 'provider' }}>
          <FormattedMessage id="ListingPage.sellYoursLink" />
        </NamedLink>
      </div> : null}
      </div>

      {!isIframe ?
        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={isInquiryModalOpen}
          onClose={onCloseInquiryModal}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {inquiryFormComponent}
        </Modal>
      : (isInquiryModalOpen ? inquiryFormComponent : null)}
    </div>
  );
};

export default SectionAuthorMaybe;
